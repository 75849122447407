// src/config.js
function getCurrentURL() {
    const url = new URL(window.location.href);
    url.search = "";
    url.hash = "";

    let newUrl = url.toString();
    newUrl=newUrl.slice(0, -1);
    return newUrl;
}

// cognitoConfig.js
const cognitoConfig = {
    ClientId: process.env.REACT_APP_CLIENT_ID, // Your App Client ID (without client secret)
    AppWebDomain: process.env.REACT_APP_DOMAIN,
    TokenScopesArray: [],
    RedirectUriSignIn: getCurrentURL(), // Your redirect URI for sign-in
    RedirectUriSignOut: getCurrentURL(), // Your redirect URI for sign-out
    IdentityProvider: "", // Leave empty if not using a federated identity provider
    ResponseType: "code", // Required for PKCE
    Storage: sessionStorage // Use session storage for security
};
export default cognitoConfig;
