import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box, Typography, Avatar, Button, Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DataManagementIcon from '@mui/icons-material/Storage';
import CustomerSupportIcon from '@mui/icons-material/SupportAgent';
import CustomerListIcon from '@mui/icons-material/People';
import AdminSettingIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import QuizIcon from '@mui/icons-material/Quiz';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CodeIcon from '@mui/icons-material/Code';

const Sidebar = ({ userName, group, loginAs, onLogout, onLogoutAs }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const isActive = (path) => {

        return location.pathname === path
    };

    const menuItemStyle = (path) => ({
        padding: '12px 16px',
        color: isActive(path) ? 'text.primary' : 'text.secondary',
        fontWeight: isActive(path) ? 'bold' : 'normal',
        fontSize: '.95rem',
        '&:hover': {
            color: 'text.primary',
            fontWeight: 'bold',
        },
    });

    return (
        <Drawer
            variant="permanent"
            sx={{
                display: 'block',
                width: 250,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: 250,
                    boxSizing: 'border-box',
                    position: 'fixed',
                    height: `100vh`,
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: '#f7f7f7',
                    borderRight: '1px solid #e0e0e0',
                },
            }}
        >
            <Box sx={{ p: 2, textAlign: 'center', borderBottom: '1px solid', borderColor: '#e0e0e0' }}>
                <Avatar sx={{ bgcolor: '#d9d9d9', width: 48, height: 50, mx: 'auto', mb: 1 }}>
                    {userName ? userName[0].toUpperCase() : ''}
                </Avatar>

                <Typography variant="subtitle1" fontWeight="bold" color="#3d3d3d">{userName}</Typography>
                <Typography variant="caption" color="#9e9e9e">
                    {loginAs && (
                        <span> &nbsp;{t('welcomeBar.actingAs')} <strong>{loginAs}</strong></span>
                    )}
                </Typography>
            </Box>

            <List>
                {(group == 'customer' || loginAs) && (
                    <>
                        <ListItem button="true" component={Link} to="/data-management" sx={menuItemStyle('/data-management')}>
                            <ListItemIcon sx={{ color: isActive('/data-management') ? 'primary.main' : 'text.secondary' }}>
                                <DataManagementIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.dataManagement')} />
                        </ListItem>
                        <ListItem button="true" component={Link} to="/member-list" sx={menuItemStyle('/member-list')}>
                            <ListItemIcon sx={{ color: isActive('/member-list') ? 'primary.main' : 'text.secondary' }}>
                                <AdminSettingIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.memberList')} />
                        </ListItem>
                    </>
                )}
                {(group == 'admin' && loginAs === null) && (
                    <>
                        <ListItem button="true" component={Link} to="/customer-list" sx={menuItemStyle('/customer-list')}>
                            <ListItemIcon sx={{ color: isActive('/customer-list') ? 'primary.main' : 'text.secondary' }}>
                                <CustomerListIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.customerList')} />
                        </ListItem>
                        <ListItem button="true" component={Link} to="/system-admin-list" sx={menuItemStyle('/system-admin-list')}>
                            <ListItemIcon sx={{ color: isActive('/system-admin-list') ? 'primary.main' : 'text.secondary' }}>
                                <CustomerListIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.systemAdminList')} />
                        </ListItem>
                        <ListItem button="true" component={Link} to="/admin-setting" sx={menuItemStyle('/admin-setting')}>
                            <ListItemIcon sx={{ color: isActive('/admin-setting') ? 'primary.main' : 'text.secondary' }}>
                                <AdminSettingIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.adminSetting')} />
                        </ListItem>
                    </>
                )}
                {(group == 'member' && loginAs === null) && (
                    <>
                        <ListItem button="true" component={Link} to="/data-management" sx={menuItemStyle('/data-management')}>
                            <ListItemIcon sx={{ color: isActive('/data-management') ? 'primary.main' : 'text.secondary' }}>
                                <DataManagementIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sideBar.dataManagement')} />
                        </ListItem>
                    </>
                )}
            </List>
            <Divider />
            <Box sx={{ mt: 'auto', p: 2, textAlign: 'center', borderTop: '1px solid', borderColor: '#e0e0e0' }}>
                {loginAs && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onLogoutAs}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.875rem',
                            padding: '6px 10px',
                            borderRadius: 1,
                            mb: 1,
                            width: '100%',
                            borderColor: '#3d3d3d',
                            color: '#3d3d3d',
                        }}
                    >
                        {t('welcomeBar.exitActingMode')}
                    </Button>
                )}
                <LanguageSwitcher sx={{ mb: 1, width: '100%' }} />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onLogout}
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.875rem',
                        padding: '8px 12px',
                        backgroundColor: 'grey.900',
                        color: 'common.white',
                        '&:hover': {
                            backgroundColor: 'grey.800',
                        },
                        width: '100%',
                        mt: 2,
                    }}
                >
                    {t('welcomeBar.signout')}
                </Button>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
