import React, { useEffect, useState } from 'react'; 
import authService from './services/AuthService';
import Users from './components/Users';
import Files from './Files';
import Sidebar from './components/Sidebar';
import { Box, Button, Typography } from '@mui/material';
import { ThemeProviderWrapper } from './context/ThemeProviderWrapper';
import { jwtDecode } from "jwt-decode";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminSetting from './components/AdminSetting';
import UserForm from './components/UserForm';
import ComingSoon from './components/ComingSoon';
import './i18n';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(authService.isAuthenticated());
    const [loggedInUser, setLoggedInUser] = useState(authService.getCurrentUser());
    const [loggedInAs, setLoggedInAs] = useState(authService.getLoginAs());
    const [group, setGroup] = useState(authService.getGroup());
    const [loading, setLoading] = useState(true);

    function handleExpiredSession() {
        const accessToken = authService.getAccessToken();
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp < currentTime) {
                setIsAuthenticated(false);
                setLoggedInAs(null);
                setGroup(null);
                setLoggedInUser(null);
                authService.logout();
            }
        }
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            authService.parseCognitoResponse(window.location.href);
            setIsAuthenticated(authService.isAuthenticated());
            setLoggedInUser(authService.getCurrentUser());
            setGroup(authService.getGroup());
        }
        setLoading(false);

        handleExpiredSession();
        const intervalId = setInterval(handleExpiredSession, 10000);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, []);

    const handleLogin = () => {
        authService.login();
    };

    const handleLogout = () => {
        authService.logout();
        setIsAuthenticated(false);
    };

    const handleLoginAs = (loginAs) => {
        authService.setLoginAs(loginAs);
        setLoggedInAs(loginAs);
    };

    const handleLogoutAs = () => {
        authService.setLoginAs(null);
        setLoggedInAs(null);
    };

    const renderContent = () => {
        if (group == 'admin' && loggedInAs === null) {
            return (
                <Routes>
                    <Route path="/customer-list" element={<Users key="customer"  handleLoginAs={handleLoginAs} profile='customer' />} />
                    <Route path="/system-admin-list" element={<Users key="admin"  handleLoginAs={handleLoginAs} profile='admin' />} />
                    <Route path="/admin-setting" element={<AdminSetting />} />
                    <Route path="/" element={<Users handleLoginAs={handleLoginAs} profile='customer' />} />
                    <Route path="*" element={<Users handleLoginAs={handleLoginAs} profile='customer'/>} />
                </Routes>
            );
        } else if (group == 'customer' || loggedInAs !== null) {
            return (
                <Routes>
                    <Route path="/data-management" element={<Files />} />
                    <Route path="/member-list" element={<Users key="admin"  handleLoginAs={handleLoginAs} profile='member' />} /> {/* Route for Add Client */}
                    <Route path="/admin-setting" element={<AdminSetting />} />

                    <Route path="/" element={<Files />} />
                    <Route path="*" element={<Files />} />
                </Routes>
            );
        } else if (group == 'member' || loggedInAs !== null){
            return (
                <Routes>
                    <Route path="/data-management" element={<Files />} />

                    <Route path="/" element={<Files />} />
                    <Route path="*" element={<Files />} />
                </Routes>
            );

        }
        return null;
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Typography variant="h6">Loading...</Typography>
            </Box>
        );
    }

    return (
        <ThemeProviderWrapper>
            <Router>
                {isAuthenticated ? (
                    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                        {/* Sidebar with user info and navigation */}
                        <Sidebar
                            userName={loggedInUser}
                            group={group}
                            loginAs={loggedInAs}
                            onLogout={handleLogout}
                            onLogoutAs={handleLogoutAs}
                        />
                        {/* Main Content Area */}
                        <Box sx={{ flexGrow: 1, padding: 3 }}>
                            {renderContent()}
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button variant="contained" onClick={handleLogin}>Login</Button>
                    </Box>
                )}
            </Router>
        </ThemeProviderWrapper>
    );
};

export default App;
