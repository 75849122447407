import React, { useState, useEffect } from 'react';
import AuthService from './services/AuthService';
import fileService from './services/FileService';
import MultipleFileUpload from './components/MultipleFileUpload';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Button, Snackbar, Alert, IconButton, TextField, InputAdornment } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';

function Files() {
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();
    const loginAs = AuthService.getLoginAs();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const fetchFiles = async () => {
        try {
            const loginAsUri = loginAs ? `?loginAs=${loginAs}` : '';
            const data = await fileService.getFiles(loginAsUri);
            setFiles(data);

            // Calculate total file size
            const totalSize = data.reduce((acc, file) => acc + file.size, 0);
            setTotalFileSize(totalSize);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleSelectFile = (fileId) => {
        setSelectedFiles((prevSelected) =>
            prevSelected.includes(fileId) ? prevSelected.filter((id) => id !== fileId) : [...prevSelected, fileId]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedFiles(files.map((file) => file.fileName));
        } else {
            setSelectedFiles([]);
        }
    };

    const formatFileSize = (size) => {
        if (size < 1024) {
            return `${size} B`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(0)} KB`;
        } else if (size < 1024 * 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(0)} MB`;
        } else {
            return `${(size / (1024 * 1024 * 1024)).toFixed(0)} GB`;
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(date);
    };

    const handleDeleteSelected = () => {
        setOpenConfirmDialog(true); // Open the confirmation dialog
    };

    const confirmDelete = async () => {
        try {
            const deletePromises = selectedFiles.map((fileName) =>
                fileService.deleteFile(fileName)
            );
            await Promise.all(deletePromises);

            // Filter out deleted files from the state
            setFiles((prevFiles) => prevFiles.filter((file) => !selectedFiles.includes(file.fileName)));
            setSnackbarMessage(`${selectedFiles.length} ${t('files.deletedMessage')}`);
            setOpenSnackbar(true);
            setSelectedFiles([]);
        } catch (error) {
            setSnackbarMessage(t('files.deleteErrorMessage'));
            setOpenSnackbar(true);
            console.error('Error deleting files:', error);
        }
        setOpenConfirmDialog(false); // Close the confirmation dialog
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleUploadComplete = () => {
        setSnackbarMessage(t('files.uploadSuccess'));
        setOpenSnackbar(true);
        fetchFiles();
    };

    const handleRefresh = () => {
        fetchFiles();
    };

    const filteredFiles = files.filter((file) =>
        file.fileName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ p: 4 }}>
            {/* Header Section */}
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <Typography variant="h5" fontWeight="bold">
                    {t('files.knowledgeBase')}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                    {t('files.totalFileSize')}: {formatFileSize(totalFileSize)}
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                    <IconButton onClick={handleRefresh}>
                        <RefreshIcon color="primary" />
                    </IconButton>
                    <MultipleFileUpload onUploadComplete={handleUploadComplete} />
                </Box>
            </Box>

            {/* Search Bar */}
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'left' }}>
                <TextField
                    variant="outlined"
                    placeholder={t('files.searchPlaceholder')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    size="small"
                    sx={{ width: '30%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {/* Files Table */}
            <TableContainer component={Paper} elevation={0} sx={{ mt: 2, borderRadius: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedFiles.length === filteredFiles.length && filteredFiles.length > 0}
                                    indeterminate={selectedFiles.length > 0 && selectedFiles.length < filteredFiles.length}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                           
                            
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.200', textAlign: 'left' }}>
                                {t('files.fileName')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.200', textAlign: 'left' }}>
                                {t('files.lastModified')}
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'grey.200', textAlign: 'left' }}>
                                {t('files.size')}
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFiles.map((file) => (
                            <TableRow key={file.fileName} hover>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedFiles.includes(file.fileName)}
                                        onChange={() => handleSelectFile(file.fileName)}
                                    />
                                </TableCell>
                                <TableCell>{file.fileName}</TableCell>
                                <TableCell>{formatDate(file.lastModified)}</TableCell>
                                <TableCell>{formatFileSize(file.size)} </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Delete Button */}
            <Button
                variant="contained"
                color="error"
                onClick={handleDeleteSelected}
                disabled={selectedFiles.length === 0}
                sx={{ mt: 2, textTransform: 'none' }}
            >
                {t('files.deleteSelectedFiles')}
            </Button>

            {/* Snackbar Notification */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        padding: 2,
                        width: '300px', // Adjust size if needed
                    },
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {t('files.confirmDeleteTitle')}
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
                    <Button
                        onClick={() => setOpenConfirmDialog(false)}
                        sx={{
                            textTransform: 'none',
                            color: 'primary.main',
                            fontWeight: 'bold',
                        }}
                    >
                        {t('files.cancel')}
                    </Button>
                    <Button
                        onClick={confirmDelete}
                        variant="contained"
                        color="error"
                        sx={{
                            textTransform: 'none',
                            fontWeight: 'bold',
                            padding: '6px 16px',
                            '&:hover': { backgroundColor: 'error.dark' },
                        }}
                        autoFocus
                    >
                        {t('files.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
}

export default Files;
