import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';

const ContactAdmin = () => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSubmit = () => {
        // Perform validation before submitting
        if (!email || !message) {
            setSnackbarMessage('Please provide both your email and message.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        // Example logic for sending the contact message (you can replace this with your actual API call)
        console.log(`Message sent to Admin from ${email}: ${message}`);
        
        // Show success notification
        setSnackbarMessage('Your message has been sent to the Admin.');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);

        // Clear form after submission
        setEmail('');
        setMessage('');
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" gutterBottom>
                Contact Support
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any issues or questions, please feel free to reach out to our admin team by filling out the form below.
            </Typography>

            <TextField
                fullWidth
                label="Your Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
            />

            <TextField
                fullWidth
                label="Your Message"
                variant="outlined"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ mb: 2 }}
            />

            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Send Message
            </Button>

            {/* Snackbar for feedback */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ContactAdmin;
