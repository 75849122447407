// ComingSoon.js

import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';

function ComingSoon({ title = "Coming Soon", subtitle = "Stay tuned for something amazing!" }) {
  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: '20vh' }}>
      <Paper elevation={3} sx={{ padding: 5, borderRadius: 2 }}>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {subtitle}
        </Typography>
      </Paper>
    </Container>
  );
}

export default ComingSoon;
