import AuthService from './AuthService';

class FileService{
    constructor() {
        this.apiBaseUrl=process.env.REACT_APP_API_URL;
    }

    async getFiles(loginAsUri){
        const token = AuthService.getAccessToken();
        const url = `${this.apiBaseUrl}/files` + loginAsUri;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    async deleteFile(fileName){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/files/${fileName}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to delete file: ${fileName}`);
        }
    }

    async generatePresign(fileData){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/files-upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ files: fileData }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); // Define `data` here with the JSON response
        return data;
    }
}
const fileService = new FileService();
export default fileService;    